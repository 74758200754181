import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Bengal Spice has been established as a family run business since 1957,
        with the vision of creating the best Indian Restaurant in London.
      </Typography>

      <Typography variant="body1" paragraph>
        Our high standard of customer service is reflected in our loyal customer
        base. Through three generations of experience, we have excelled in the
        quality of our food, service and cleanliness.
      </Typography>

      <Typography variant="body1" paragraph>
        Our menu offers a variety of Indian flavours as well as original dishes
        unique to Bengal Spice. We source the best ingredients to prepare fresh
        stocks & sauces, combining traditional recipes, exotic ingredients and
        the eastern blends of herbs & spices to bring you great tasting,
        authentic Indian meals.
      </Typography>

      <Typography variant="body1" paragraph>
        If you suffer from allergies, please enquire when ordering. Our dishes
        may contain: Gluten, Nuts, Milk, Peanuts, Soya, Mustard, Sesame Seeds,
        Lupin, Egg, Crustaceans, Celery, Fish/Fish Bone, Molluscs, Sulphur
        Dioxide. All our dishes are prepared using vegetable oil which contains
        soya. Customers with any allergies, eat at their own risk. Please call
        us before ordering if you have any questions.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
