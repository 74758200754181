const getPalette = (theme) => {
  return {
    type: "light",
    primary: { main: "#071216", hover: "#194150" },
    secondary: { main: "#071216", hover: "#194150" },
    accent: "#071216",
    info: {
      main: "#071216",
    },
    error: {
      main: "#FF0040",
    },
    white: {
      main: "#FFF",
    },
  };
};

export default getPalette;
